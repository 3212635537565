<template>
    <div class="flzixunmain">
      <div class="topbg">
        <div class="base_w flex_between">
            <div class="index_leftwidth">
                <p class="bigtext">遇到法律问题不要慌</p>
                <p class="bigtext">优质律师在线为您答疑</p>
                <div class="searchbox flexbox">
                    <p class="s-le flex_column_center">
                        <span class="remen">热门</span>
                        <span class="zixun">咨询</span>
                    </p>
                    <div class="s-ri flex_around">
                        <p>婚姻家庭</p>
                        <p>交通事故</p>
                        <p>医疗纠纷</p>
                        <p>劳动争议</p>
                    </div>
                </div>
                <div class="databox flex_between">
                    <div>
                       <span>1,593</span> 人
                       <p>今日咨询</p>
                    </div>
                    <div>
                       <span>3,842</span> 条
                       <p>律师解答</p>
                    </div>
                    <div>
                       <span>3</span> 分钟
                       <p>平均响应时间</p>
                    </div>
                </div>
            </div>
            <div class="index_rightwidth">
              <div class="common_title_box">
                <span class="title">我要提问</span>
              </div>
              <zixun :type="4"></zixun>
            </div>
        </div>
      </div>
      <!-- 推荐服务 -->
      <div class="tjbox base_w">
        <div class="common_title_box">
          <span class="title">推荐服务</span>
        </div>
        <div class="tj_qb flexbox">
          <div class="tj_item flex_center" v-for="(item,i) in Fuwulist" :key="i" @click.stop="toask(item)">
            <img :src="item.fwphoto" alt="">
            <span class="text">{{item.name}}</span>
            <span class="price" v-if="item.price > 0">￥{{item.price}}</span>
          </div>
        </div>
      </div>

      <!-- 最新咨询  最新回复  热门律师 -->
      <div class="zxbox base_w flex">
        <div class="common_index_leftwidth">
          <el-tabs v-model="activeName">
            <el-tab-pane label="最新咨询" name="first">
              <div class="qu">
                <wenda :type="2" url="typedetails" :wendalistArr="wendalist"></wenda>
              </div>
              <!-- 更多问题 -->
              <div class="morewen flexbox"  @click.stop="tourl('/typedetails')">更多问题 
                <img src="@/assets/img/right.png" alt="">
              </div>
            </el-tab-pane>
            <el-tab-pane label="最新回复" name="second">
              <div class="qu">
                <wenda :type="3" url="typedetails" :wendalistArr="wendalist"></wenda>
              </div>
              <!-- 更多问题 -->
              <div class="morewen flexbox"  @click.stop="tourl('/typedetails')">更多问题 
                <img src="@/assets/img/right.png" alt="">
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="common_index_rightwidth">
          <div class="common_title_box">
            <span class="title">热门律师</span>
          </div>
          <hotls :list="hotlawlist"></hotls>
          <guanggao :type="1"></guanggao>
        </div>
      </div>

      <!-- 分类咨询 律师活跃度 -->
      <div class="flbox base_w flex">
        <div class="common_index_leftwidth">
          <div class="common_title_box">
            <span class="title" style="font-weight:600">分类咨询</span>
          </div>
          <div class="toptab">
            <div class="iconright flexbox" @click.stop="tourl('/typedetails')">全部类型 <img src="@/assets/img/right.png" alt=""></div>
            <div class="flexbox knolbox">
              <span 
                v-for="(item,index) in  lawKnowledgelist" 
                :key="index"
                :class="[activeTabName == index ? 'active' : '']"
                @click.stop="handleClick(item,index)"
                >{{item.name}}</span>
            </div>
          </div>

          <!-- 内容 -->
          <div class="qufenlei" v-if="wentypedalist.length > 0">
            <wenda :type="3" url="typedetails" :wendalistArr="wentypedalist"></wenda>
          </div>
          <!-- 更多问题 -->
          <div class="morewen flexbox"  @click.stop="tourl('/typedetails?fenglei=' + typename)" v-if="wentypedalist.length > 0">更多问题 
            <img src="@/assets/img/right.png" alt="">
          </div>

          <NoData v-if="wentypedalist.length <= 0"></NoData>

        </div>
        <div class="common_index_rightwidth">
          <div class="common_title_box">
            <span class="title" style="font-weight:600">律师活跃榜</span>
          </div>
          <div class="huo">
            <div class="huo_item flex_between" v-for="(item,i) in activelist" :key="i">
              <div class="left1 flexbox">
                <p class="num">{{i+1}}</p>
                <div class="ren flexbox">
                  <img :src="item.photo ? item.photo : '@/assets/img/ls.png'" alt="">
                  <div class="renri">
                    <p class="text1">{{item.lawyerName}}律师</p>
                    <p class="text2">我在线，欢迎咨询</p>
                  </div>
                </div>
              </div>
              <div class="btnbox" @click.stop="tourl('/wenls?lawid=' + item.id)">向他咨询</div>
            </div>
          </div>
          <guanggao :type="2"></guanggao>
        </div>
      </div>

      <!-- 法律资讯 -->
      <div class="flzxbox base_w">
        <div class="title_box">
          <span class="title1">法律资讯</span>
          <span class="t1">知法才能守法，懂法才能用法</span>
        </div>
        <div class="newszx">
          <div class="leftnewszx flexbox">
            <div class="newsitem1" v-for="(item,i) in informationlist" :key="i" @click.stop="tourl('/infodetails?id=' + item.id)">
              <img :src="item.photo" alt="" class="img11">
              <p class="n1 line1">{{item.title}}</p>
              <p class="n2">{{item.createTime}}</p>
            </div>
          </div>
          <div class="rightnewzx">
            <news :type="2" :list="informationlist1"></news>
          </div>
        </div>
      </div>

      <!-- 知识普法 -->
      <div class="zspfbox base_w">
        <div class="common_title_box">
          <span class="title">知识普法</span>
        </div>
        <div class="zspf flex">
          <div class="zspfitem " v-for="(item,i) in LawKnowledgelistAll" :key="i">
            <div class="fi">
              <span></span> {{item.name}}
            </div>
            <div class="textf">
              <span v-for= '(data,num) in item.subnameArr' :key="num" @click.stop="tourl('/wenfa?title=' + item.name)">{{data.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import zixun  from '@/components/zixun'
import wenda  from '@/components/wenda'
import news  from '@/components/news'
import hotls  from '@/components/hotls'
import guanggao  from '@/components/guanggao'
import NoData  from '@/components/NoData'

import apiUrl from '@/api/main'

export default {
    components: {zixun,wenda,news,hotls,guanggao,NoData},
    data() {
        return {
          activeName: 'first',
        
          activeTabName:0,
          Fuwulist:[], // 服务列表
          wendalist:[], // 咨询和回复列表
          hotlawlist:[], // 热门律师
          activelist:[], // 活跃
          lawKnowledgelist:[], // 分类咨询方式nav
          typename:'', // 分类名称
          wentypedalist:[], // 分类咨询列表
          informationlist:[], // 资讯列表 2条
          informationlist1:[], // 资讯列表 6条
          LawKnowledgelistAll:[], // 知识普法，所有分类标签

        };
    },
    computed: {},
    watch: {},
    created() {
      
    },
    mounted() {
      this.getlsFuwulist(); // 咨询方式 服务
      this.getwendalist(); // 最新咨询和最新回复
      this.gethostlist(); // 热门律师
      this.getactivelist(); // 律师活跃榜
      this.getlawKnowledge(); // 分类咨询列表
      this.getinformation(); // 法律资讯 2
      this.getinformationB(); // 法律资讯 6
      this.getlsLawKnowledgelist(); // 所有知识普法标签服务
      // 跳转回顶部
      document.documentElement.scrollTop = 0;
    },
    methods: {
      // 跳转
      tourl(url) {
        this.$router.push({
          path: url
        })
      },
      // 提问
      toask(item){
        this.$router.push({
          path: '/toask?goflzixunback=true&price=' + item.price + '&type=' + item.name
        })
      },
      handleClick(item, index) {
        this.activeTabName = index;
        this.typename = item.name;
        this.gettypewendalist();
      },
      // 获取 所有服务 咨询方式
      getlsFuwulist() {
        let data = {
          fuwutype: "金刚区"
        }
        apiUrl.lsFuwulist(data).then((res) => {
          this.Fuwulist = res.rows;
          apiUrl.lsFuwulist({fuwutype: "精选服务"}).then((res1) => {
            this.Fuwulist =  res.rows.concat(res1.rows);
          })

        })
      },
      // 获取最新咨询 和 最新回复
      getwendalist() {
        let data = {
          pageSize: 10,
          pageNum: 1
        }
        apiUrl.wendalist(data).then((res) => {
            this.wendalist = res.rows;
        })
      },
      // 获取分类下的 咨询列表
      gettypewendalist() {
        let data = {
          pageSize: 10,
          pageNum: 1,
          fenglei: this.typename,
        }
        apiUrl.wendalist(data).then((res) => {
            this.wentypedalist = res.rows;
        })
      },
      // 获取 热门律师
      gethostlist() {
        let data = {
          pageSize: 3,
          pageNum: 1,
          tuijian: 1,
          orderByColumn: "haopinhlv",
          isAsc: "desc"
        }
        apiUrl.lsLawyerindex(data).then((res) => {
           
            res.rows.forEach((item,i) => {
              if(item.goodsIds){
                item.goodsIdsArr = item.goodsIds.split(',');
              }
            });

            this.hotlawlist = res.rows;
        })
      },

      // 获取 律师活跃榜
      getactivelist() {
        let data = {
          pageSize: 5,
          pageNum: 1,
          huoyue: 1,
        }
        apiUrl.lsLawyerindex(data).then((res) => {
           
            res.rows.forEach((item,i) => {
              if(item.goodsIds){
                item.goodsIdsArr = item.goodsIds.split(',');
              }
            });

            this.activelist = res.rows;
        })
      },

      // 获取分类咨询列表
      getlawKnowledge(){
        let data = {
          pageSize: 5,
          pageNum: 1,
          pId: 0,
          userId: localStorage.getItem('userId')
        }
        apiUrl.lsLawKnowledgelist(data).then((res) => {
            this.lawKnowledgelist = res.rows;

            if(res.rows.length > 0){
              this.typename = res.rows[0].name;
              this.gettypewendalist(); // 分类下的咨询列表
            }
        })
      },

      // 获取法律资讯 2条
      getinformation(){
        let data = {
          userId:localStorage.getItem('userId'),
          pageSize: 2,
          pageNum: 2,
        }
        apiUrl.lsInformationlist(data).then((res) => {
           this.informationlist = res.rows;
        });
      },
      // 获取法律资讯 6条
      getinformationB(){
        let data = {
          userId:localStorage.getItem('userId'),
          pageSize: 6,
          pageNum: 1,
        }
        apiUrl.lsInformationlist(data).then((res) => {
           this.informationlist1 = res.rows;
        });
      },

      // 知识普法   所有分类咨询列表
      getlsLawKnowledgelist() {
        let data = {
          pId: 0,
          userId: localStorage.getItem('userId')
        }
        apiUrl.lsLawKnowledgelist(data).then((res) => {

            this.$nextTick(()=>{
              res.rows.forEach((item,i) => {
                item.subnameArr = [];
                let data1 = {
                    pId:item.id,
                    userId: localStorage.getItem('userId')
                  }
                  apiUrl.lsLawKnowledgelist(data1).then((res1) => {
                    // item.subnameArr = res1.rows;
                    Object.assign(item.subnameArr, res1.rows);
                  });
              });

              setTimeout(() => {
                this.LawKnowledgelistAll = res.rows;
              }, 900);
               
            });  
            
        });

        
      },
    },
    }
</script>
<style lang='scss'>
//@import url(); 引入公共css类
.flzixunmain{
    padding-bottom: 67px;

    // 顶部
    .topbg{
        background: url('../../assets/img/bg4.png') no-repeat;
        background-size: 100% 100%;
        height: 500px;
        width: 100%;
        .index_leftwidth{
            margin:88px 0 56px;
            width: 500px;
            .bigtext{
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 54px;
                letter-spacing: 10px;
            }
            .searchbox{
                margin-top: 40px;
                width: 492px;
                height: 72px;
                background: rgba(236,236,236,0.24);
                border-radius: 12px;
                backdrop-filter: blur(1px);
                .s-le{
                  width: 80px;
                  height: 72px;
                  background: #FFFFFF;
                  border-radius: 12px 0px 0px 12px;
                  .remen{
                    font-size: 24px;
                    font-family: PangMenZhengDao;
                    color: #305BFE;
                    letter-spacing: 5px;
                    font-weight: 600;
                  }
                  .zixun{
                    font-size: 24px;
                    font-family: PangMenZhengDao;
                    color: #333;
                    letter-spacing: 2px;
                    font-weight: 600;
                  }
                }
                .s-ri{
                  width: 410px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #FFFFFF;
                  p{
                    display: inline-block;
                    width: 25%;
                    text-align: center;
                  }
                }
            }
            .databox{
              color: #fff;
              font-size: 16px;
              color: #FFFFFF;
              margin-top: 56px;
              p{
                margin-top: 15px;
              }
              span{
                font-size: 32px;
                font-family: ADAMCGPRO;
                color: #FFFFFF;
                margin-right: 10px;
              }
            }
        }
        // 发布咨询
        .index_rightwidth{
            background: url('../../assets/img/bg5.png') no-repeat;
            background-size: 100% 100%;
            width: 595px;
            height: 500px;
            padding: 0 33px;
            .common_title_box{
              margin-top: 27px;
              margin-bottom: 16px;
              font-size: 18px;
              span{
                color: #FFFFFF;
              }
            }
        }
    }

    // 推荐服务
    .tjbox{
      margin-top:40px;

      .tj_qb{
        flex-wrap: wrap;

        .tj_item{
          width: 342px;
          height: 96px;
          background: #F2F5FF;
          border-radius: 10px;
          line-height: 96px;
          text-align: center;
          margin-right: 24px;
          margin-top: 24px;
          cursor: pointer;
          img{
            width: 36px;
            height: 36px;
            margin-right: 24px;
          }
          .text{
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            margin-right: 23px;
          }
          .price{
              font-size: 24px;
              font-weight: 500;
              color: #F64733;
          }
        }
        .tj_item:nth-child(4n){
          margin-right: 0px;
        }
      }
    }

    // 最新咨询  最新回复  热门律师
    .zxbox{
      margin-top: 50px;
      padding-bottom: 47px;
      border-bottom: 1px solid #EEEEEE;
      .common_title_box{
        margin-bottom: 16px;
      }
      
     
      .el-tabs__item{
        font-size: 22px;
      }
    }

    .el-tabs__nav-wrap::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px; 
        background-color: transparent;
        z-index: 1;
    }
    .el-tabs__active-bar{
      background-color: #305BFE;
    }
    .el-tabs__item.is-active{
      color: #305BFE;
      font-weight: 600;
    }
    // .el-tabs__item{
    //   font-size: 22px;
    // }
    .el-tabs__item:hover{
      color: #305BFE;
    }


  // 分类咨询
  .flbox{
    margin-top: 41px;
    border-bottom: 2px solid #333;
    padding-bottom: 57px;
    .common_index_leftwidth{
      // margin-bottom: 27px;
      .toptab{
        position: relative;
        margin-top: 32px;
        .knolbox{
          margin-top: 16px;
          span {
            display: inline-block;
            margin-right: 32px;
            cursor: pointer;
            font-size: 22px;
            color: #333333;
            padding-bottom: 8px;
          }
          .active{
            font-weight: 500;
            color: #305BFE;
            border-bottom: 2px solid #305BFE;
          }
        }
      }
      .el-tabs__header{
        width: 500px;
      }
      .iconright{
        color: #777777;
        font-size: 16px;
        position: absolute;
        right: 0;
        top: 6px;
        cursor: pointer;
        img{
          width: 22px;
          height: 22px;
          margin-left: 4px;
        }
      }
      
    }

    // 律师活跃榜
    .huo{
      width: 586px;
      background: #F8F8F8;
      border-radius: 10px;
      padding: 0 32px;
      margin-top: 16px;
      .huo_item{
        padding: 24px 0 16px;
        border-bottom: 1px solid #E6E6E6;
        .left1{
          .num{
            width: 28px;
            height: 28px;
            background: #FEA03D;
            border-radius: 5px;
            text-align: center;
            line-height: 23px;
            color: #fff;
            font-size: 19px;
            margin-right: 24px;
          }
          .ren{
           
            img{
              width: 88px;
              height: 88px;
              background: #ECECEC;
              border-radius: 12px;
              margin-right: 20px;
            }
            .renri{
              height: 88px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .text1{
                font-size: 18px;
                font-weight: 500;
                color: #333333;
              }
              .text2{
                font-size: 18px;
                color: #666666;
              }
            }
          }
        }
        .btnbox{
          width: 104px;
          height: 36px;
          line-height: 36px;
          border-radius: 18px;
          font-size: 16px;
        }
      }
      .huo_item:nth-last-child(1){
        border-bottom: 1px solid transparent;
      }
    }
  }

  // 法律资讯
  .flzxbox{
    margin-top: 49px;
    padding-bottom: 53px;
    border-bottom: 2px solid #333;
    .title_box{
      display: flex;
      align-items: center;
      .title1{
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .t1{
        color: #777777;
        font-size: 16px;
        margin-left: 16px;
      }
    }
    .newszx{
      margin-top: 28px;
      display: flex;
      align-items: flex-start;
      .leftnewszx{
        width:586px;
        .newsitem1{
          width: 271px;
          border-radius: 8px;
          height: 258px;
          background: #F5F5F5;
          margin-right: 24px;
          cursor: pointer;
          .img11{
            width: 271px;
            height: 158px;
            border-radius: 8px 8px 0 0;
          }
          .n1{
              font-size: 17px;
              font-weight: 500;
              color: #333333;
              line-height: 24px;
              margin: 12px 12px 0 12px;
              &:hover{
                color: #305BFE;
              }
          }
          .n2{
              font-size: 14px;
              color: #777777;
              margin: 32px 12px 12px 12px;
          }
        }
      }
      .rightnewzx{
        width:830px;
      }
    }
   
  }

  // 知识普法
  .zspfbox{
    margin-top: 49px;
    .zspf{
      flex-wrap: wrap;
      .zspfitem{
        margin: 24px 24px 0 0;
        width: 342px;
        background: #F5F5F5;
        border-radius: 8px;
        padding: 27px 32px 29px 32px;
     
        .fi{
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 24px;
            span{
              display: inline-block;
              width: 8px;
              height: 19px;
              background: #305BFE;
              border-radius: 2px;
              margin-right: 12px;
            }
        }
        .textf{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          span{
            width: 50%;
            font-size: 16px;
            color: #666666;
            margin-bottom: 16px;
            cursor: pointer;
            &:hover{
              color: #305BFE;
            }
          }
         
        }
      }
      .zspfitem:nth-child(4n){
        margin-right: 0px;
      }
    }
  }





  // 更多问题
  .morewen{
    font-size: 16px;
    color: #777777;
    padding-top: 22px;
    cursor: pointer;
    img{
      width: 22px;
      height: 22px;
      margin-left: 4px;
    }
  }


  .qu{
    overflow: auto;
    height: 840px;
  }
  
  .qu::-webkit-scrollbar{
    display: none;
  }

  .qufenlei{
    overflow: auto;
    height: 830px;
    padding-top: 10px;
  }

  .qufenlei::-webkit-scrollbar{
    display: none;
  }




}
</style>